<template>
    <!--新增对话框-->
    <el-dialog title="详情" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉id:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintId }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        订单id:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.billId }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉人身份:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintTypeUse == 2 ? '货主' : detail.complaintTypeUse == 3 ? '司机' : '' }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                       评分
                    </el-col>
                    <el-col :span="16">
                     {{ detail.complaintScore }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>


        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉人姓名:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.userFullName }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        被投诉人姓名:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintFullName }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row><el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉人昵称:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.userNiceName }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        被投诉人昵称:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintNiceName }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row><el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉人手机:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.userPhone }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        被投诉人手机:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintPhone }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉类别:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintCategory }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉说明:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaint }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉时间:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.createdTime }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        投诉状态:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.complaintState == 1 ? '待处理' : '已处理' }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        处理时间:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.processedTime }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="8">
                        处理结果:
                    </el-col>
                    <el-col :span="16">
                        {{ detail.processingResults }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            detail: {},
            dialogVisible: false,
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
            this.detail = {}
        },
        init(row) {
            this.dialogVisible = true;
            this.detail = row
        },
    }
}
</script>

<style scoped>
.el-col {
    margin-bottom: 10px;
}
</style>
